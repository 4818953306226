<template>
  <div class="doctor-avatar">
    <v-img
      v-bind="$attrs"
      :aspect-ratio="$attrs.ratio"
      v-if="isSmart"
      style="max-width: 100%;"
    />
    <gravity-image-manager
      v-else
      v-bind="{
        ...$attrs,
        value: value
      }"
      @input="(e) => $emit('input', e)"
    />

    <div class="doctor-avatar__magic">
      <v-tooltip right :open-delay="750" :max-width="300">
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            x-small
            fab
            depressed
            :color="isSmart ? 'success' : 'normal'"
            v-on="{
              ...on,
              click: () => $emit('input', isSmart ? 'ce' : 'sm')
            }"
          >
            <v-icon x-small>fa-magic</v-icon>
          </v-btn>
        </template>
        <div style="font-size: 14px; line-height: 20px">
          {{ isSmart
            ? 'Автовыравнивание включено. Результат будет виден на сайте после сохранения профиля.'
            : 'Включить автовыравнивание. Результат будет виден на сайте после сохранения профиля.'
          }}
        </div>
      </v-tooltip>
    </div>

  </div>
</template>

<script>
import GravityImageManager from '@/components/elements/gravity/GravityImageManager.vue'
const GRAVITY_SMART = 'sm'

export default {
  components: {
    GravityImageManager
  },
  props: {
    value: {
      type: String
    }
  },
  computed: {
    isSmart () {
      return this.value === GRAVITY_SMART
    }
  }
}
</script>

<style lang="scss" scoped>
.doctor-avatar {
  position: relative;
}
.doctor-avatar__magic {
  position: absolute;
  top: 8px;
  left: 8px;
}
</style>
