<template>
  <gravity-provider
    v-bind="$attrs"
    v-on="$listeners"
    v-slot="{ items, value, input }"
  >
    <div class="gravity-picker">
      <div
        class="gravity-picker__region"
        v-for="item in items"
        :key="item"
        @click="() => input(item)"
        :class="item === value ? 'gravity-picker__region--active' : ''"
      >
        <slot name="label">
          <template v-if="showLabels">
            {{ item }}
          </template>
        </slot>
      </div>
    </div>

  </gravity-provider>
</template>

<script>
import GravityProvider from './GravityProvider.vue'
export default {
  components: {
    GravityProvider
  },
  props: {
    showLabels: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.gravity-picker {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.gravity-picker__region {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s background;
  background: rgba(#fff, 0.2);
  &:hover {
    background: rgba(#fff, 0.5);
    box-shadow: 0 0 0 2px rgba(blue, 0.7);
  }
}

.gravity-picker__region--active {
  background: rgba(blue, 0.1);
  box-shadow: 0 0 0 2px rgba(blue, 0.7);
  &:hover {
    background: rgba(blue, 0.2);
  }
}
</style>
