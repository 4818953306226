<template>
  <div
    class="gravity-image"
    :class="gravity ? `gravity-image--${gravity}` : ''"
    :style="styles"
  />
</template>

<script>
export default {
  props: {
    src: {
      type: String
    },
    gravity: {
      type: String
    }
  },
  computed: {
    styles () {
      return {
        backgroundImage: `url(${this.src})`
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$options: (
  no: 50% 0,
  so: 50% 100%,
  ea: 100% 50%,
  we: 0 50%,
  ce: 50% 50%,
  noea: 100% 0,
  nowe: 0 0,
  soea: 100% 100%,
  sowe: 0 100%,
);

.gravity-image {
  background-color: #eee;
  background-size: cover;
}

@each $name, $option in $options {
  .gravity-image--#{$name} {
    background-position: $option
  }
}

</style>
