<script>
export const FOCUS_CE = 'ce'
export const FOCUS_WE = 'we'
export const FOCUS_EA = 'ea'
export const FOCUS_NO = 'no'
export const FOCUS_SO = 'so'
export const FOCUS_SOEA = 'soea'
export const FOCUS_SOWE = 'sowe'
export const FOCUS_NOEA = 'noea'
export const FOCUS_NOWE = 'nowe'

const items = {
  FOCUS_NOWE,
  FOCUS_NO,
  FOCUS_NOEA,

  FOCUS_WE,
  FOCUS_CE,
  FOCUS_EA,

  FOCUS_SOWE,
  FOCUS_SO,
  FOCUS_SOEA,
}

export default {
  props: {
    value: {
      type: String,
      validator: (prop) => Object.values(items).includes(prop)
    },
  },
  data () {
    return {
      innerValue: this.value,
      items,
    }
  },
  methods: {
    input (item) {
      this.innerValue = item
      this.$emit('input', this.innerValue)
    }
  },
  watch: {
    value (value) {
      this.innerValue = value
    }
  },
  render () {
    const { default: defaultSlot } = this.$scopedSlots
    const { input, items, innerValue: value } = this
    const scope = { input, items, value }
    return defaultSlot ? defaultSlot(scope) : null
  }
}
</script>
