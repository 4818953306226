<template>
  <div class="gravity-image-manager">
    <ratio-span
      :ratio="ratio"
    />
    <gravity-image
      :src="src"
      :gravity="innerValue"
      class="gravity-image-manager__preview"
    />
    <gravity-picker
      :value="innerValue"
      @input="input"
      class="gravity-image-manager__picker"
    />
  </div>
</template>

<script>
import RatioSpan from '@/components/elements/ratio/RatioSpan.vue'
import GravityImage from './GravityImage'
import GravityPicker from './GravityPicker.vue'
export default {
  components: {
    RatioSpan,
    GravityImage,
    GravityPicker,
  },
  props: {
    ratio: {
      type: [Number, Function],
    },
    src: {
      type: String
    },
    value: {
      type: String,
      default: 'ce'
    }
  },
  data () {
    return {
      innerValue: this.value
    }
  },
  watch: {
    value (value) {
      this.innerValue = value
    }
  },
  methods: {
    input (value) {
      this.innerValue = value
      this.$emit('input', this.innerValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.gravity-image-manager {
  position: relative;
}

.gravity-image-manager__preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.gravity-image-manager__picker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
